import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Article1 from "../../images/PressReleaseArticle-1.svg";
import Article2 from "../../images/PressReleaseArticle-2.svg";
import Article3 from "../../images/PressReleaseArticle-3.svg";
import Article4 from "../../images/PressReleaseArticle-4.svg";
import Article5 from "../../images/PressReleaseArticle-5.jpeg";
import Article6 from "../../images/deautshe-fetch.jpeg";
import Article7 from "../../images/c4e_article.svg";
import Article8 from "../../images/peaq_article.png";
import Article9 from "../../images/gameswift_article.svg";
import Article10 from "../../images/guest_lectures.svg";
import Article11 from "../../images/Dtravel.svg";
import coinDesk from "../../images/Coindesk_logo.svg";
import coinTelegraph from "../../images/Coinelegraph.svg";
import { members } from "./constant";
import { useNewsLaterContext } from "../../context/NewsLaterContext";

const featureItems = [
  {
    logo: coinDesk,
  },
  {
    logo: coinTelegraph,
  },
];
const pressReleasesData = [
  {
    title:
      "Hardware and software, alone and in combination: Bosch has everything it takes for the mobility of the future",
    date: "Sept 04, 2023",
    imageSrc: Article1, // Update the path to the image
    articleLink:
      "https://www.bosch-presse.de/pressportal/de/en/hardware-and-software-alone-and-in-combination-bosch-has-everything-it-takes-for-the-mobility-of-the-future-257728.html",
  },

  {
    title: "The road to a trustworthy digital “Economy of Everything”",
    date: "March 01, 2023",
    imageSrc: Article3, // Update the path to the image
    articleLink:
      "https://www.bosch.com/research/research-fields/digitalization-and-connectivity/economy-of-things/economy-of-things-a-technology-and-business-evolution/",
  },
  {
    title: "Opening the door to the Economy of Things in the Web3 age",
    date: "Feb 22, 2023",
    imageSrc: Article2, // Update the path to the image
    articleLink: "https://www.bosch-ai.com/industrial-ai/fetch-ai-foundation/",
  },
  {
    title:
      "Fetch.ai Partners with Bosch to Form a Foundation to Promote Industrial Applications Using Web3 Technology",
    date: "Feb 21, 2023",
    imageSrc: Article4, // Update the path to the image
    articleLink: "https://fetch.ai/blog/fetch-ai-partners-with-bosch",
  },
  {
    title:
      "Announcing the Agent Mobility Hackathon. Find and Connect: Explore the Future of Mobility with Industry Leaders.",
    date: "Nov 15, 2023",
    imageSrc: Article5, // Update the path to the image
    articleLink: "https://fetch.ai/blog/hackathon-mobility",
  },
  {
    title:
      "Deutsche Telekom, Bosch, and Fetch.ai Foundation Collaborate to Advance AI",
    date: "Feb 13, 2024",
    imageSrc: Article6, // Update the path to the image
    articleLink: "https://www.telekom.com/en/media/media-information/archive/dt-cooperates-with-bosch-and-the-fetch-ai-foundation-1058956",
  },
  {
    title:
      "C4E Joins Forces with Fetch.ai to Revolutionize the Energy Market Through AI Agent Technology",
    date: "Mar 1, 2024",
    imageSrc: Article7, // Update the path to the image,
    articleLink: "https://medium.com/chain4-energy/the-c4es-joining-the-fetch-ai-foundation-5ca22a108694",
  },
  {
    title:
      "Peaq joins the Fetch.ai Foundation to unlock AI for DePINs",
    date: "Mar 7, 2024",
    imageSrc: Article8, // Update the path to the image,
    articleLink: "https://www.peaq.network/blog/peaq-joins-the-100-mln-fetch-ai-foundation-to-unlock-ai-for-depins",
  },
  {
    title:
      "Gameswift joins the Fetch.ai Foundation",
    date: "Aug 29, 2024",
    imageSrc: Article9, // Update the path to the image,
    articleLink: "https://mirror.xyz/0x69699A9551aF5fbBEf4B2d84E0A708A0f36F9779/QqJ5Po-AhL56o_EXPfvOxh_9XTul84fYCiRWeSNeHSg",
  },
  {
    title:
      "Introducing Guest Lectures",
    date: "Sep 26, 2024",
    imageSrc: Article10, // Update the path to the image,
    articleLink: "https://fetch.ai/blog/introducing-the-new-guest-lectures-series-exploring-ai-agents-with-fetch-ai-in-collaboration-with-fetch-ai-foundation-partners",
  },
  {
    title:
      "Dtravel joins Fetch.ai Foundation and aims to revolutionaze travel industry with peer-to-peer rentals",
    date: "Oct 17, 2024",
    imageSrc: Article11, // Update the path to the image,
    articleLink: "https://dtravel.com/blog/dtravel-joins-the-fetch-ai-foundation-and-integrates-agent-into-fetch-ai-marketplace/",
  },
];
export default function PressReleases() {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const { dispatch, actions } = useNewsLaterContext();

  const filteredAndSortedData = pressReleasesData
    .filter((pressRelease) =>
      pressRelease.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      
      // If sortOrder is 'asc', sort in ascending order, else sort in descending order
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });


  const itemsPerPage = 4;
  const pageCount = Math.ceil(filteredAndSortedData.length / itemsPerPage);
  const paginatedData = filteredAndSortedData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <>
      <div className=" py-[50px] md:py-[80px] px-[16px] ">
        <h1 className=" font-bold text-[46px]   text-[#0D283E] text-center">
          Our
          <span className="ml-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
            Members
          </span>
        </h1>
        <div className="relative flex lg:flex-row flex-col justify-center gap-[20px] w-full items-center mx-auto p-5 lg:py-[32px]">
          {members.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              key={index}
              className="bg-gradient-to-br from-[#4789CD] via-[#478acd00] to-[#478acd] rounded-[12px] md:rounded-[16px] px-[1.5px] w-full  md:max-w-[183px]"
              rel="noreferrer"
            >
              <div
                style={{
                  boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                }}
                className="rounded-[12px] md:rounded-[16px] bg-white w-full text-center"
              >
                <img
                  src={item.logo}
                  alt="ecosystemImage"
                  className="px-[20px] mx-auto my-auto md:py-6 py-[20px] h-[72px] w-full"
                />
              </div>
            </a>
          ))}
        </div>
        <div className="flex items-center justify-center">
          <button
            className="text-[16.83px] font-medium text-white py-[16px] px-[26px] rounded-[6px] bg-gradient-to-r from-blue-500 to-cyan-400"
            onClick={() =>
              dispatch({
                type: actions.SET_IS_MODAL_OPEN,
                payload: true,
              })
            }
          >
            Join us!
          </button>
        </div>
      </div>
      <section className="bg-white">  
        <div className="py-8 px-6 container mx-auto justify-center items-center  md:px-[100px] md:pb-[100px]">
          <h2 className=" font-bold text-[46px]  md:py-[75px] pt-[50px] pb-[70px] leading-[48px] text-[#0D283E] text-center">
            Press{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
              Releases
            </span>
          </h2>
         
          <div className="text-[#0D283E]  flex-col flex  justify-center items-center gap-[40px]">
            <div className="flex gap-[20px] bg-[#EDF2F9] w-full py-[16px] px-[20px] rounded-[50px]">
              <select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className="rounded-[30px] w-[30%] text-[#0D283E]"
              >
                <option value="asc">Sort by ascending</option>
                <option value="desc">Sort by Descending</option>
              </select>
              <input
                type="text"
                placeholder="Search in content hub"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="rounded-[30px] w-[70%] text-[#0D283E]"
              />
            </div>
                        
            {paginatedData.map((pressRelease, index) => (
              <div
                key={index}
                id={index}
                className="flex md:flex-row flex-col w-full md:gap-[121px] gap-[30px] "
              >
                <div className=" md:min-w-[480px] md:max-w-[480px] max-[350px] min-w-[350px] ">
                  <img
                    className="object-cover w-full h-full rounded-[20px]"
                    src={pressRelease.imageSrc}
                    alt="articleimage"
                  />
                </div>
                <div className="flex flex-col gap-4 md:items-start">
                  {pressRelease.articleLink ? <a
                    href={pressRelease.articleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className=" text-[#0D283E] text-[16px] px-[20px] py-[10px] rounded-full border border-[#0D283E4D] font-semibold">
                      ARTICLE
                    </button>
                  </a> : null}
                  <p className="text-[20px] md:text-[28px]   text-[#0D283E] text-left font-medium">
                    {pressRelease.title}
                  </p>
                  <p className="  text-[#0D283E] text-left text-[16px] font-semibold">
                    {pressRelease.date}
                  </p>
                </div>
              </div>
            ))}

            {/* Pagination */}
            <ReactPaginate
              previousLabel={"←"}
              nextLabel={"→"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              breakClassName={"break-item"}
              breakLinkClassName={"break-link"}
              previousClassName={"prev-item"}
              previousLinkClassName={"prev-link"}
              nextClassName={"next-item"}
              nextLinkClassName={"next-link"}
            />
            <style jsx>{`
          .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            padding: 0;
          }

          .page-item {
            margin: 0 4px;
            display: inline-block;
          }

          .page-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border: 1px solid #0D283E;
            color: #0D283E;
            border-radius: 4px;
            cursor: pointer;
            text-decoration: none;
          }

          .active .page-link {
            background-color: #4789CD4D;
            color: white;
          }

          .prev-item,
          .next-item {
            margin: 0 8px;
          }

          .prev-link,
          .next-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border: 1px solid #0D283E;
            color: #0D283E;
            border-radius: 4px;
            cursor: pointer;
            text-decoration: none;
          }

          .break-item {
            margin: 0 4px;
          }

          .break-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            color: #0D283E;
            cursor: default;
          }
        `}</style>
          </div>
        </div>
        <section className="bg-[#EDF2F9]">
          <div className=" container mx-auto py-[50px] md:pt-[80px]  md:px-[120px] px-[16px] ">
            <div className="hidden md:block w-[987.759px] h-[987.759px] absolute right-[266.12px] top-[1800px] -z-10 rounded-full "></div>
            <h1 className=" font-bold text-[46px]   text-[#0D283E] text-center">
              Featured{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
                in
              </span>
            </h1>
            <div className="relative flex justify-center gap-[20px] items-center mx-auto pt-[40px]">
              {featureItems.map((item, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-br from-[#4789CD] via-[#478acd00] to-[#478acd] rounded-[12px] md:rounded-[16px] px-[1.5px] w-full md:w-[183px]"
                >
                  <div
                    style={{
                      boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                    }}
                    className="rounded-[12px] md:rounded-[16px] bg-white w-full md:w-[180px] text-center"
                  >
                    <img
                      src={item.logo}
                      alt="ecosystemImage"
                      className="px-[20px] mx-auto my-auto md:py-6 py-[20px] h-[88px]"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
